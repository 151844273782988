@mixin sms-survey() {
  .script {
    .steps {
      .step {
        list-style: none;
        display: flex;
        margin-bottom: $baseline;

        .key {
          flex: 1;
        }

        .info {
          flex: 11;
        }

        .warning {
          color: red;
        }

        .field-info {
          .name,
          .type,
          .options {
            margin: 0;
          }
        }
      }

      .field-info {
          padding: $baseline / 2 $baseline $baseline / 2 $baseline;
      }

      .options-list {
          .option {
              list-style: disc;

              .key {
                  font-weight: $bold;
                  margin-right: $baseline / 2;
              }

              .skip {
                  color: $primary-color;
              }
          }
      }
    }
  }
}

