@import 'admin:variables';
@import 'admin:icon-mixins';
@import '@core/utility';
@import '@core/grid';
@import '@core/breakpoint';
@import '@core/typography';
@import '@core/forms';

@import "sms-survey";

.sb-admin {
  .sms-survey {
    @include sms-survey;
  }

  .sms-field {
    display: flex;
    flex-direction: column;
    .sms-character-counter {
        $hybrid-props: get-input-props((), input-colors input-label-hybrid);
        @include input-label-absolute-floating-text(
            $font-size: map-get($hybrid-props, error-font-size),
            $line-height: map-get($hybrid-props, error-line-height),
            $x-offset: map-get($hybrid-props, error-x-offset),
            $padding: map-get($hybrid-props, error-padding),
            $border-radius: map-get($hybrid-props, error-border-radius),
        );
        display: flex;
        align-self: flex-start;
        background-color: map-get($hybrid-props, input-background-color);
        color: $text-color-light;
        font-weight: $semibold;
        // position: absolute;
        // left: get-side(map-get(get-input-props(), input-padding), left);
        // bottom: get-side(map-get(get-input-props(), input-padding), bottom) / 2;
        // box-shadow: 0 map-get($hybrid-props, error-background-bleed) * -1 map-get($hybrid-props, input-background-color),
                    // 0 map-get($hybrid-props, error-background-bleed) map-get($hybrid-props, input-background-color);
        .column + .column {
            margin-left: 0.8em;
        }
        span {
            font-weight: $normal;
        }
    }
  }
  .input-group .sms-field {
      flex-grow: 1;
      margin-top: 0;
  }
  .sms-broadcast-complete {
      margin-left: 0;
  }
  .communication-schedule {
      .input:not(.label-visible) {
          .input-label {
              width: 0;
              height: 0;
              position: absolute;
              visibility: hidden;
          }
          .input-field {
              margin-top: 0;
          }
      }

      .input.input-condition {
          margin-top: $baseline;
      }

      .input-group-label {
          width: 100%;
      }
  }
  .response-handling {
      .open-ended-response-msg-cont {
          transition: all 0.3s;
          max-height: 153px;

          .input {
              margin-top: 0;
          }
      }

      .open-ended-response-msg-cont.hidden {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
      }
  }
  .sms-report {
      .figure-cont {
          margin: $baseline 0;
          figure {
              margin: 0;
          }
      }
      .list-of-figures > * {            
          margin: $baseline * 2 0;
      }
      .figure-title {
          font-size: #{24/$em}rem;
          margin-bottom: $baseline;
      }
      .chart {
          .axis {
              fill: $dark-gray;
          }
          & > path {
              stroke: $dark-gray;
          }
          text {
              text-transform: uppercase;
              font-size: #{14/$em}rem;
          }
      }
      .report-table {
          th {
              text-align: left;
          }
      }
      .horizontal-bar {
          position: relative;
          background-color: $dark-gray;
          height: 20px;
          margin: 10px 0 10px 110px;
      }
      .horizontal-bar-caption {
          position: absolute;
          top: 0;
          left: -110px;
          display: inline-block;
          width: 100px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
      }
      .horizontal-bar-count {
          position: absolute;
          line-height: 20px;
          margin-left: 5px;
      }
  }

  .generate-tip-dialog {
    .or,
    .send-to-myself {
      display: flex;
      justify-content: center;
      margin-top: $baseline;
    }
  }
}
